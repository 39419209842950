/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * API to get important events relevant for logged-in user
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Employee Dto
 * @export
 * @interface Employees
 */
export interface Employees {
    /**
     * Employee ID
     * @type {number}
     * @memberof Employees
     */
    employeeId: number;
    /**
     * Employee's full name
     * @type {string}
     * @memberof Employees
     */
    fullName: string;
    /**
     * Employee's first name
     * @type {string}
     * @memberof Employees
     */
    firstName: string;
    /**
     * Employee's last name
     * @type {string}
     * @memberof Employees
     */
    lastName?: string;
    /**
     * Employee's middle name
     * @type {string}
     * @memberof Employees
     */
    middleName?: string;
    /**
     * Employee's title
     * @type {string}
     * @memberof Employees
     */
    title?: string;
    /**
     * Employee's about description
     * @type {string}
     * @memberof Employees
     */
    about?: string;
    /**
     * Employee's picture url
     * @type {string}
     * @memberof Employees
     */
    profilePictureUrl?: string;
    /**
     * List of employees hard and soft skills
     * @type {Array<string>}
     * @memberof Employees
     */
    skills?: Array<string>;
    /**
     * Indicates whether employee is liked by the company client
     * @type {boolean}
     * @memberof Employees
     */
    favourite?: boolean;
    /**
     * Is this employee team lead
     * @type {boolean}
     * @memberof Employees
     */
    isTeamLeader?: boolean;
    /**
     * Employee's CAO function name
     * @type {string}
     * @memberof Employees
     */
    functionName?: string;
    /**
     * Employee's city
     * @type {string}
     * @memberof Employees
     */
    city?: string;
    /**
     * Review score of this employee per shift
     * @type {number}
     * @memberof Employees
     */
    reviewScore?: number;
    /**
     * Average review score of this employee
     * @type {number}
     * @memberof Employees
     */
    reviewScoreAverage?: number;
    /**
     * Total number of reviews performed on this employee
     * @type {number}
     * @memberof Employees
     */
    reviewScoreCount?: number;
    /**
     * Employee's number of shifts in the past at all companies
     * @type {number}
     * @memberof Employees
     */
    numberOfCompaniesShifts?: number;
    /**
     * Employee's number of shifts in the past at all companies client
     * @type {number}
     * @memberof Employees
     */
    numberOfCompaniesClientsShifts?: number;
}

export function EmployeesFromJSON(json: any): Employees {
    return EmployeesFromJSONTyped(json, false);
}

export function EmployeesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Employees {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'fullName': json['fullName'],
        'firstName': json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'about': !exists(json, 'about') ? undefined : json['about'],
        'profilePictureUrl': !exists(json, 'profilePictureUrl') ? undefined : json['profilePictureUrl'],
        'skills': !exists(json, 'skills') ? undefined : json['skills'],
        'favourite': !exists(json, 'favourite') ? undefined : json['favourite'],
        'isTeamLeader': !exists(json, 'isTeamLeader') ? undefined : json['isTeamLeader'],
        'functionName': !exists(json, 'functionName') ? undefined : json['functionName'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'reviewScore': !exists(json, 'reviewScore') ? undefined : json['reviewScore'],
        'reviewScoreAverage': !exists(json, 'reviewScoreAverage') ? undefined : json['reviewScoreAverage'],
        'reviewScoreCount': !exists(json, 'reviewScoreCount') ? undefined : json['reviewScoreCount'],
        'numberOfCompaniesShifts': !exists(json, 'numberOfCompaniesShifts') ? undefined : json['numberOfCompaniesShifts'],
        'numberOfCompaniesClientsShifts': !exists(json, 'numberOfCompaniesClientsShifts') ? undefined : json['numberOfCompaniesClientsShifts'],
    };
}

export function EmployeesToJSON(value?: Employees | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'fullName': value.fullName,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'middleName': value.middleName,
        'title': value.title,
        'about': value.about,
        'profilePictureUrl': value.profilePictureUrl,
        'skills': value.skills,
        'favourite': value.favourite,
        'isTeamLeader': value.isTeamLeader,
        'functionName': value.functionName,
        'city': value.city,
        'reviewScore': value.reviewScore,
        'reviewScoreAverage': value.reviewScoreAverage,
        'reviewScoreCount': value.reviewScoreCount,
        'numberOfCompaniesShifts': value.numberOfCompaniesShifts,
        'numberOfCompaniesClientsShifts': value.numberOfCompaniesClientsShifts,
    };
}

