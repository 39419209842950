/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * API to get important events relevant for logged-in user
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Details of the shift, if a registration is shift-related.
 * @export
 * @interface ShiftDto
 */
export interface ShiftDto {
    /**
     * The name of the shift.
     * @type {string}
     * @memberof ShiftDto
     */
    shiftName: string;
    /**
     * The name of the project, the shift belongs to.
     * @type {string}
     * @memberof ShiftDto
     */
    projectName: string;
}

export function ShiftDtoFromJSON(json: any): ShiftDto {
    return ShiftDtoFromJSONTyped(json, false);
}

export function ShiftDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shiftName': json['shiftName'],
        'projectName': json['projectName'],
    };
}

export function ShiftDtoToJSON(value?: ShiftDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shiftName': value.shiftName,
        'projectName': value.projectName,
    };
}

